<template>
  <div class="callBoxWarpper" v-if="visible">
      <div class="top">
          <div class="bg"></div>
          <div @click="falseVisible"><i class="iconfont icon-guanbi"></i></div>
      </div>
      <div class="phoneNo">昊森咨询电话：028-87750979</div>
      <div class="callBoxContentWarpper">
          <div :class="{'kefu':item.isKefu,'kehu':!item.isKefu}" v-for="(item,index) in contentList" :key="index">
              <div class="name">{{item.name}}</div>
              <span class="content">{{item.content}}</span>
          </div>
      </div>
      <div class="inputWarpper">
          <textarea placeholder="请输入" v-model="text"></textarea>
          <div class="ok" @click="ok">发送</div>
      </div>
  </div>
</template>

<script>
export default {
    props:{
        visible:{type:Boolean,default:false}
    },
    data() {
        return {
            contentList:[{name:'昊森科技客服(028-87750979)',content:'您好！欢迎致电昊森科技：028-87750979',isKefu:true}],
            text:""
        }
    },
    methods:{
        ok(){
            if(this.text){
                this.contentList.push({name:'客户',content:this.text,isKefu:false})
                this.text = ""
                setTimeout(()=>{
                    this.contentList.push({name:'昊森科技客服(028-87750979)',content:'您好！欢迎致电昊森科技：028-87750979',isKefu:true})
                },1000)
            }
        },
        falseVisible(){
            this.$emit('update:visible',false)
        }
    }
}
</script>

<style lang="scss" scoped>
.callBoxWarpper{
    width: 400px;
    height: 555px;
    position: absolute;
    top: -300px;
    right: 70px;
    z-index: 2;
    background: #ffffff;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.16);
    cursor: default;
    .top{
        display: flex;
        padding: 0 15px;
        justify-content: space-between;
        align-items: center;
        .bg{
            background: url('../../assets/img/callBg.png') no-repeat;
            width: 100px;
            height: 30px;
        }
        .icon-guanbi{
            cursor: pointer;
        }
    }
    .phoneNo{
        width: 100%;
        height: 50px;
        background: #009049;
        color: #ffffff;
        font-size: 14px;
        font-weight: 700;
        text-align: center;
        line-height: 50px;
    }
    .callBoxContentWarpper{
        height: 325px;
        width: 100%;
        border-bottom: 1px solid #DEDEDE;
        padding: 20px;
        box-sizing: border-box;
        overflow: scroll;
        overflow-x: hidden;
        overflow-y: auto;
        div{
            line-height: 40px;

        }
        .kefu{
            text-align: left;
            font-size: 14px;
            .name{    
                color: #999999;
            }
            .content{
                border: 1px solid #E6E6E6;
                color: #333333;
                border-radius: 2px 21px 21px 21px;
                padding: 12px 20px;
            }
        }
        .kehu{
            text-align: right;
            font-size: 14px;
            .name{    
                color: #999999;
            }
            .content{
                border: 1px solid #E6E6E6;
                color: #333333;
                border-radius: 21px 2px 21px 21px ;
                padding: 12px 20px;
            }
        }
    }
    .inputWarpper{
        height: 115px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        textarea{
            width: 387px;
            height: 68px;
            font-size: 14px;
            border: none;
            resize:none;
        }
        .ok{
            width: 89px;
            height: 40px;
            background: #009049;
            border-radius: 4px;
            font-size: 14px;
            color: #ffffff;
            text-align: center;
            line-height: 40px;
            align-self: flex-end;
            cursor: pointer;
        }
    }
}
</style>