var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"right__title"},[_vm._v(" 电子床头卡、门口机系统 ")]),_c('i',{staticClass:"title-buttom"}),_c('p',{staticClass:"right__details"},[_vm._v(" 该系统与医院HIS、CIS、运维系统进行无缝对接，有效的解决了医院病房传统床头卡带来的种种弊端。 ")]),_c('img',{staticClass:"bedside",attrs:{"src":require("../../../assets/img/schemeList01.jpg"),"alt":""}}),_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"right__subsection"},[_c('p',{staticClass:"right__subsection__title"},[_vm._v("相关产品")]),_c('div',{staticClass:"right__subsection__flex"},[_c('p',{staticClass:"right__subsection__english"},[_vm._v("Product Center")]),_c('router-link',{staticClass:"right__subsection__more",attrs:{"to":"/center"}},[_vm._v("更多产品详情 >>")])],1)]),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right__subsection"},[_c('p',{staticClass:"right__subsection__title"},[_vm._v("功能描述")]),_c('p',{staticClass:"right__subsection__english"},[_vm._v("Functional description")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"description__first-line"},[_c('p',[_vm._v("可手动控制熄、亮屏")]),_c('p',[_vm._v("主治医生介绍内容展示")]),_c('p',[_vm._v("展示病房内患者入住情况")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"description__two-line"},[_c('p',[_vm._v("支持与病房内床头机交互，实现定格显示")]),_c('p',{staticClass:"description__two-line__two"},[_vm._v("责任护士介绍内容展示")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right__product"},[_c('div',{staticClass:"product__first-line__call"},[_c('div',{staticClass:"product__first-line__call-img"},[_c('div',[_c('p',{staticClass:"product__first-line__title"},[_vm._v("病床分机")]),_c('p',{staticClass:"product__first-line__english"},[_vm._v("Hospital Bed Extension")])])])]),_c('div',{staticClass:"product__first-line__call"},[_c('div',{staticClass:"product__first-line__call-img intercom-host"},[_c('div',[_c('p',{staticClass:"product__first-line__title"},[_vm._v("病房门口屏")]),_c('p',{staticClass:"product__first-line__english"},[_vm._v(" Ward Door Screen ")])])])]),_c('div',{staticClass:"product__first-line__call LCD"},[_c('div',{staticClass:"product__first-line__call-img LCD-img"},[_c('div',[_c('p',{staticClass:"product__first-line__title"},[_vm._v("医护对讲主机")]),_c('p',{staticClass:"product__first-line__english"},[_vm._v(" Medical Intercom Host ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right__subsection"},[_c('p',{staticClass:"right__subsection__title"},[_vm._v("页面展示")]),_c('p',{staticClass:"right__subsection__english"},[_vm._v("Page Display")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-display"},[_c('img',{attrs:{"src":require("../../../assets/img/schemeList02Display01.jpg"),"alt":""}}),_c('img',{attrs:{"src":require("../../../assets/img/schemeList02Display02.jpg"),"alt":""}}),_c('img',{attrs:{"src":require("../../../assets/img/schemeList02Display03.jpg"),"alt":""}}),_c('div',[_vm._v("病房分机页面")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-display__two-line"},[_c('img',{attrs:{"src":require("../../../assets/img/schemeList02Display04.jpg"),"alt":""}}),_c('img',{attrs:{"src":require("../../../assets/img/schemeList03Display05.jpg"),"alt":""}}),_c('div',[_vm._v("病房分机页面")])])
}]

export { render, staticRenderFns }