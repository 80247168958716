<template>
  <div class="warpper">
    <section class="section">
      <div class="contentWarpper scheme-title">
        <img src="@/assets/img/centerNavTitle.png" alt="" />
      </div>
    </section>
    <section class="scheme contentWarpper">
      <p class="scheme-current-position">
        您当前的位置：<router-link to="/home">首页</router-link> > <span @click="goCenter()">产品中心</span>
        <span>{{ details }}</span>
      </p>
      <div class="scheme-content">
        <div class="scheme-left">
          <p class="scheme-left__title">智慧病房解决方案</p>
          <p class="scheme-left__title list-li">
            智慧呼叫对讲系统
          </p>
          <p class="scheme-left__title list-li">
            电子床头卡、门口机系统
          </p>
          <p class="scheme-left__title list-li">
            智慧生命体征采集系统
          </p>
          <p class="scheme-left__title list-li">
            中央远程生命体征采集系统
          </p>
          <p class="scheme-left__title list-li">
            医用气体报警系统
          </p>
          <p class="scheme-left__title list-li">
            智慧吸氧计量系统
          </p>
          <p class="scheme-left__title list-li">
            智慧输液报警系统
          </p>
          <p class="scheme-left__title list-li">
            智慧护士查房系统
          </p>
          <p class="scheme-left__title list-li">
            智慧床旁交互系统
          </p>
          <p class="scheme-left__title contact-us">
            联系我们<span>/ Contact Us</span>
          </p>
          <div class="contact-us-content">
            <p>
              <img src="@/assets/img/schemeAboutUs01.png" alt="" />
              <span>服务热线：028-87750979</span>
            </p>
            <p>
              <img src="@/assets/img/schemeAboutUs02.png" alt="" />
              <span>销售电话：028-87750979</span>
            </p>
            <p>
              <img src="@/assets/img/schemeAboutUs03.png" alt="" /><span>电子邮箱：775892738 @qq.com</span>
            </p>
          </div>
        </div>
        <div class="scheme-right" v-if="centerIdex">
          <p class="right__title">
            产品中心
          </p>
          <i class="title-buttom"></i>
          <div class="center-list">
            <div>
              <img src="@/assets/img/center01.png" alt="" />
              <p>
                <span class="product-name">医护对讲主机</span>
                <span class="product-details" @click="goDetails(1)" >查看详情 ></span>
              </p>
            </div>

            <div>
              <img src="@/assets/img/center02.png" alt="" />
              <p>
                <span class="product-name">病床分机</span>
                <span class="product-details" @click="goDetails(2)" >查看详情 ></span>
              </p>
            </div>

            <div>
              <img src="@/assets/img/center03.png" alt="" />
              <p>
                <span class="product-name">病房门口屏</span>
                <span class="product-details" @click="goDetails(3)" >查看详情 ></span>
              </p>
            </div>

            <div>
              <img src="@/assets/img/center04.png" alt="" />
              <p>
                <span class="product-name">点阵双面走廊屏</span>
                <span class="product-details" @click="goDetails(4)" >查看详情 ></span>
              </p>
            </div>

            <div>
              <img src="@/assets/img/center05.png" alt="" />
              <p>
                <span class="product-name">数码管显示屏</span>
                <span class="product-details" @click="goDetails(5)" >查看详情 ></span>
              </p>
            </div>
            <div>
              <img src="@/assets/img/center06.png" alt="" />
              <p>
                <span class="product-name">呼叫分机</span>
                <span class="product-details" @click="goDetails(6)" >查看详情 ></span>
              </p>
            </div>
            <div>
              <img src="@/assets/img/center07.png" alt="" />
              <p>
                <span class="product-name">液晶走廊屏</span>
                <span class="product-details" @click="goDetails(7)" >查看详情 ></span>
              </p>
            </div>
            <div>
              <img src="@/assets/img/center08.png" alt="" />
              <p>
                <span class="product-name">淋浴间呼叫分机</span>
                <span class="product-details" @click="goDetails(8)" >查看详情 ></span>
              </p>
            </div>
            <div>
              <img src="@/assets/img/center09.png" alt="" />
              <p>
                <span class="product-name">卫生间呼叫分机</span>
                <span class="product-details" @click="goDetails(9)" >查看详情 ></span>
              </p>
            </div>
            <div>
              <img src="@/assets/img/center10.png" alt="" />
              <p>
                <span class="product-name">门灯</span>
                <span class="product-details">查看详情 ></span>
              </p>
            </div>
            <div>
              <img src="@/assets/img/center11.png" alt="" />
              <p>
                <span class="product-name">值班分机</span>
                <span class="product-details">查看详情 ></span>
              </p>
            </div>
            <div>
              <img src="@/assets/img/center12.png" alt="" />
              <p>
                <span class="product-name">地址码</span>
                <span class="product-details"  @click="goDetails(12)" >查看详情 ></span>
              </p>
            </div>
            <div>
              <img src="@/assets/img/center13.png" alt="" />
              <p>
                <span class="product-name">电子血压计</span>
                <span class="product-details">查看详情 ></span>
              </p>
            </div>
            <div>
              <img src="@/assets/img/center14.png" alt="" />
              <p>
                <span class="product-name">指尖血氧仪</span>
                <span class="product-details">查看详情 ></span>
              </p>
            </div>
            <div>
              <img src="@/assets/img/center15.png" alt="" />
              <p>
                <span class="product-name">指尖血氧仪</span>
                <span class="product-details">查看详情 ></span>
              </p>
            </div>
            <div>
              <img src="@/assets/img/center16.png" alt="" />
              <p>
                <span class="product-name">心电贴</span>
                <span class="product-details">查看详情 ></span>
              </p>
            </div>
            <div>
              <img src="@/assets/img/center17.png" alt="" />
              <p>
                <span class="product-name">血氧监测指环</span>
                <span class="product-details">查看详情 ></span>
              </p>
            </div>
            <div>
              <img src="@/assets/img/center18.png" alt="" />
              <p>
                <span class="product-name">吸氧计时器</span>
                <span class="product-details" @click="goDetails(18)" >查看详情 ></span>
              </p>
            </div>
            <div>
              <img src="@/assets/img/center19.png" alt="" />
              <p>
                <span class="product-name">智能输液监控器</span>
                <span class="product-details">查看详情 ></span>
              </p>
            </div>
            <div>
              <img src="@/assets/img/center20.png" alt="" />
              <p>
                <span class="product-name">巡房刷卡器</span>
                <span class="product-details"  @click="goDetails(20)" >查看详情 ></span>
              </p>
            </div>
            <div>
              <img src="@/assets/img/center21.png" alt="" />
              <p>
                <span class="product-name">IC 卡</span>
                <span class="product-details">查看详情 ></span>
              </p>
            </div>
          </div>
        </div>
        <div class="scheme-right" v-if="intercomHost">
          <intercomHost></intercomHost>
        </div>
        <div class="scheme-right" v-if="bedExtension">
          <bedExtension></bedExtension>
        </div>
        <div class="scheme-right" v-if="doorwayScreen">
          <doorwayScreen></doorwayScreen>
        </div>
        <div class="scheme-right" v-if="doubleSided">
          <doubleSided></doubleSided>
        </div>
        <div class="scheme-right" v-if="digitalTube">
          <digitalTube></digitalTube>
        </div>
        <div class="scheme-right" v-if="callExtension">
          <callExtension></callExtension>
        </div>
        <div class="scheme-right" v-if="liquidCrystal">
          <liquidCrystal></liquidCrystal>
        </div>
        <div class="scheme-right" v-if="toiletExtension">
          <toiletExtension></toiletExtension>
        </div>
        <div class="scheme-right" v-if="showerCall">
          <showerCall></showerCall>
        </div>
        <div class="scheme-right" v-if="addressCode">
          <addressCode></addressCode>
        </div>
        <div class="scheme-right" v-if="oxygenTiming">
          <oxygenTiming></oxygenTiming>
        </div>
        <div class="scheme-right" v-if="roundRoom">
          <roundRoom></roundRoom>
        </div>

      </div>
    </section>
  </div>
</template>
  
<script>
import intercomHost from "./component/intercomHost.vue"
import bedExtension from "./component/bedExtension.vue"
import doorwayScreen from "./component/doorwayScreen.vue"
import doubleSided from "./component/doubleSided.vue"
import digitalTube from "./component/digitalTube.vue"
import callExtension from "./component/callExtension.vue"
import liquidCrystal from "./component/liquidCrystal.vue"
import toiletExtension from "./component/toiletExtension.vue"
import showerCall from "./component/showerCall.vue"
import addressCode from "./component/addressCode.vue"
import oxygenTiming from "./component/oxygenTiming.vue"
import roundRoom from "./component/roundRoom.vue"
export default {
  components: {
    'intercomHost': intercomHost,
    'bedExtension': bedExtension,
    'doorwayScreen': doorwayScreen,
    'doubleSided': doubleSided,
    'digitalTube': digitalTube,
    'callExtension': callExtension,
    'liquidCrystal': liquidCrystal,
    'toiletExtension': toiletExtension,
    'showerCall': showerCall,
    'addressCode': addressCode,
    'oxygenTiming': oxygenTiming,
    'roundRoom': roundRoom,
  },
  data() {
    return {
      details: "",
      centerIdex: true,
      intercomHost: false,
      bedExtension: false,
      doorwayScreen: false,
      doubleSided: false,
      digitalTube: false,
      callExtension: false,
      liquidCrystal: false,
      toiletExtension: false,
      showerCall: false,
      addressCode: false,
      oxygenTiming: false,
      roundRoom: false,
    };
  },
  methods: {
    goCenter() {
      this.centerIdex = true;
      this.details = "";
      
      this.intercomHost = false;
      this.bedExtension = false;
      this.doorwayScreen = false;
      this.doubleSided = false;
      this.digitalTube = false;
      this.callExtension = false;
      this.liquidCrystal = false;
      this.toiletExtension = false;
      this.showerCall = false;
      this.addressCode = false;
      this.oxygenTiming = false;
      this.roundRoom = false;
    },
    goDetails(val) {
      this.centerIdex = false;
      if (val == 1) {
        this.details = " > 医护对讲主机";
        this.intercomHost = true;
      }else if (val == 2) {
        this.details = " > 病床分机";
        this.bedExtension = true;
      }else if (val == 3) {
        this.details = " > 病房门口屏";
        this.doorwayScreen = true;
      }else if (val == 4) {
        this.details = " > 点阵双面走廊屏";
        this.doubleSided = true;
      }else if (val == 5) {
        this.details = " > 数码管显示屏";
        this.digitalTube = true;
      }else if (val == 6) {
        this.details = " > 呼叫分机";
        this.callExtension = true;
      }else if (val == 7) {
        this.details = " > 液晶走廊屏";
        this.liquidCrystal = true;
      }else if (val == 8) {
        this.details = " > 淋浴间呼叫分机";
        this.toiletExtension = true;
      }else if (val == 9) {
        this.details = " > 卫生间呼叫分机";
        this.showerCall = true;
      }else if (val == 12) {
        this.details = " > 地址码";
        this.addressCode = true;
      }else if (val == 18) {
        this.details = " > 吸氧计时系统";
        this.oxygenTiming = true;
      }else if (val == 20) {
        this.details = " > 巡房刷卡器";
        this.roundRoom = true;
      }
    },
  },
};
</script>
  
<style lang="scss" scoped>
.center-list {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;

  div {
    width: 285px;
    height: 260px;
    border: 2px solid #ececec;
    margin-bottom: 10px;

    img {
      width: 100%;
      height: 190px;
    }

    p {
      height: 70px;
      width: 100%;
      padding: 20px 35px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      margin-bottom: 60px;

      span {
        display: inline-block;
        height: 22px;
        font-size: 13px;
        font-family: Microsoft YaHei UI;
        font-weight: 900;
        line-height: 20px;
        color: #333333;
        border-radius: 20px;
        padding: 3px 8px;
        border: 1px solid #8c8c8c;
        margin-top: -10px;
      }

      span:nth-child(1) {
        width: 100px;
        text-align: center;
      }
    }
  }

  div:hover {
    border-color: #009049;
    cursor: pointer;
  }

  div:hover .product-details {
    color: #009049;
    border-color: #009049;
  }
}

.warpper {
  width: 100%;

  .section {
    background: url("../../assets/img/centerBanner.jpg") no-repeat center;
    background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    height: 455px;
    padding-top: 110px;
    box-sizing: border-box;

    .scheme-title {
      img {
        width: 500px;
        height: 250px;
      }
    }
  }
}

.scheme {
  margin-top: 30px;
  margin-bottom: 75px;

  .scheme-current-position {
    font-size: 16px;
    font-family: Microsoft YaHei UI;
    color: #666666;

    span {
      cursor: pointer;
    }

    a {
      color: #009049;
    }
  }

  .scheme-content {
    display: flex;
    justify-content: space-between;

    .scheme-left {
      width: 250px;
      height: 1000px;
      margin-top: 25px;

      .scheme-left__title {
        display: block;
        width: 100%;
        height: 43px;
        background-color: #009049;
        color: #fff;
        font-size: 16px;
        font-family: Microsoft YaHei UI;
        font-weight: bold;
        line-height: 43px;
        padding-left: 20px;
        box-sizing: border-box;
        margin-bottom: 5px;

        span {
          font-size: 16px;
          font-family: Microsoft YaHei UI;
          font-weight: bold;
          line-height: 23px;
          color: #64bb8f;
          margin-left: 10px;
        }
      }

      .list-li {
        background-color: #f6f6f6;
        color: #333333;
        font-weight: normal;
        font-size: 14px;
      }

      .smart-medicine-title {
        margin-top: 15px;
      }

      .contact-us {
        margin-top: 40px;
      }

      .contact-us-content {
        width: 100%;
        height: 151px;
        border: 1px solid #009049;
        margin-top: -10px;
        box-sizing: border-box;
        padding: 6px;
        padding-top: 5.5px;

        p {
          width: 100%;
          height: 40px;
          background-color: #f6f6f6;
          margin-bottom: 7px;
          margin-top: 3.5px;
          display: flex;
          color: #333333;
          align-items: center;

          img {
            width: 22px;
            height: 22px;
            margin-left: 10px;
          }

          span {
            margin-left: 5px;
          }
        }
      }
    }

    .scheme-right {
      flex: 1;
      margin-left: 45px;

      .right__title {
        width: 100%;
        height: 45px;
        border-bottom: 1px solid #e8e8e8;
        display: block;
        line-height: 45px;
        text-align: center;
        padding-bottom: 5px;
        margin-top: 20px;
        font-size: 26px;
        font-family: Microsoft YaHei UI;
        font-weight: 400;
        line-height: 38px;
        color: #333333;
      }

      .title-buttom {
        width: 100px;
        height: 2px;
        background-color: #189142;
        border-radius: 2px;
        display: block;
        margin-left: 400px;
        margin-top: -2px;
      }
    }
  }
}
</style>
  