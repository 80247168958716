<template>
  <div class="homeWarpper">
    <section class="section-one"
      :style="{ background: 'url(' + require('../assets/img/navImg.jpg') + ')', 'background-size': '100% 100%', }">
      <i class="iconfont icon-xiangxiazhanhang show-more" @click="toNext" id="next"></i>
      <div class="nav-display">
        <img src="../../src/assets/img/navTitle.png" alt="智慧病房" class="nav-intelligent__ward" />
        <div class="nav-intelligent__ward-english">
          <span>I</span>
          <span>N</span>
          <span>T</span>
          <span>E</span>
          <span>L</span>
          <span>L</span>
          <span>I</span>
          <span>G</span>
          <span>E</span>
          <span>N</span>
          <span>T</span>
          <div class="nav__ward-english">
            <span>W</span>
            <span>A</span>
            <span>R</span>
            <span>D</span>
          </div>
        </div>
        <div class="nav__wisdom">助力医疗智慧化、现代化</div>
        <div class="nav__wisdom-english">
          <div>Intelligent ward</div>
          <div>Help the wisdom of medical treatment</div>
        </div>
        <div class="nav__learn-more">
          <router-link to="/product" class="nav__learn-more__text">了解更多 >></router-link>
        </div>
      </div>
      <div class="nav-buttom">
        <div class="nav-buttom-middle">
          <div class="nav-buttom-list" v-for="item in navButtom" :key="item.bigText">
            <div class="nav-buttom__white">
              <img :src="item.img" alt="" />
            </div>
            <div class="nav-buttom__text">
              <span class="nav-buttom__big-text">{{ item.bigText }}</span>
              <span class="nav-buttom__small-text">{{ item.small }}</span>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="about-us">
      <div class="about__left">
        <div class="about__left__content">
          <div class="about-title"><span>关于</span>昊森</div>
          <div class="about-english"> about us </div>
          <p class="paragraph">成都昊森电子科技有限公司专注于智慧病区系统的研发，生产和服务。</p>
          <p>随着互联网的飞速发展，昊森科技，结合自身的行业优势，以互联网为平台，研发出智慧病区系统，如：智能医护对讲系统、生命体征采集系统、智能输液报警系统、医用气体报警系统、智能吸氧系统等等。</p>
          <p>智慧病区系统是将物联网科技与医疗业务深度融合，由管理软件、智能医疗设备和物联网平台所组成的三位一体的软硬一体化集成解决方案。</p>
          <p>为加强医疗质量控制、提升患者满意度、提高医疗效率，为病区增收创效起到重要作用，是现代医院必备系统之一。</p>
          <div class="about-more">了解更多</div>
          <div class="about-list">
            <div class="about-list-li">
              <img src="../assets/img/home01.png" alt="">
              <div>优质服务</div>
              <div>Quality service</div>
            </div>
            <div class="about-list-li">
              <img src="../assets/img/home02.png" alt="">
              <div>信息安全</div>
              <div>Information security</div>
            </div>
            <div class="about-list-li">
              <img src="../assets/img/home03.png" alt="">
              <div>自主研发</div>
              <div>Independent research</div>
            </div>
            <div class="about-list-li">
              <img src="../assets/img/home04.png" alt="">
              <div>产品保证</div>
              <div>Product guarantee</div>
            </div>
          </div>
        </div>
      </div>
      <div class="about__right"></div>
    </section>
    <section class="product-section">
      <div class="product">
        <div class="about-title"><span>解决</span>方案</div>
        <div class="about-english"> product solutions </div>
      </div>
      <div class="product-content">
        <div class="product-list">
          <img src="../assets/img/home-01.png" alt="">
          <div class="product-text">
            <div>01</div>
            <div>智慧呼叫对讲系统</div>
            <i></i>
            <div>智能护理呼叫系统基于liuxs平台研发，具有良好的开放性和可扩展性，旨在为患者提供舒适的住院环境....</div>
          </div>
        </div>
        <div class="product-list">
          <img src="../assets/img/home-02.png" alt="">
          <div class="product-text">
            <div>02</div>
            <div>智慧电子显示系统</div>
            <i></i>
            <div>该系统与医院HIS、CIS、运维系统进行无缝对接，有效的解决了医院病房传统床头卡带来的种种弊端。支....</div>
          </div>
        </div>
        <div class="product-list">
          <img src="../assets/img/home-03.png" alt="">
          <div class="product-text">
            <div>03</div>
            <div>智慧生命体征采集系统</div>
            <i></i>
            <div>生命体征采集是一种集合大数据、物联网、移动通信等信息技术+医疗设备专业制造技术的新型护理仪器，将....</div>
          </div>
        </div>
        <div class="product-list">
          <img src="../assets/img/home-04.png" alt="">
          <div class="product-text">
            <div>04</div>
            <div>生命体征遥测系统</div>
            <i></i>
            <div>生命体征遥测系统由多参数监护仪、服务器、客户端等共同组成采集系统。负责采集，传输、显示、存储和....</div>
          </div>
        </div>
        <div class="product-list">
          <img src="../assets/img/home-05.png" alt="">
          <div class="product-text">
            <div>05</div>
            <div>输液自动化管理系统</div>
            <i></i>
            <div>每个床位或输液位的输液状态、呼叫报警信息通过无线物联网装置实时传送到护士服务台（中央站），护士在....</div>
          </div>
        </div>
        <div class="product-list">
          <img src="../assets/img/home-06.png" alt="">
          <div class="product-text">
            <div>06</div>
            <div>医用气体智慧运维系统</div>
            <i></i>
            <div>采用无线信号传输，位置布置灵活，不受线路影响，检测气体多样化，解决多种气体检测易混乱的问题信息....</div>
          </div>
        </div>
        <div class="product-list">
          <img src="../assets/img/home-07.png" alt="">
          <div class="product-text">
            <div>07</div>
            <div>智慧吸氧系统</div>
            <i></i>
            <div>智慧吸氧系统该装置以最小系统为处理核心，配合传感器实现病人吸氧的同步计时吧费交互，实时显示吸氧时长....</div>
          </div>
        </div>
        <div class="more">
          <div>了解更多</div>
        </div>
      </div>
    </section>

    <section class="product-section case">
      <div class="product">
        <div class="about-title"><span>合作</span>案例</div>
        <div class="about-english"> cooperation cases </div>
      </div>
      <div class="product-content case-content">
      </div>
    </section>


    <!-- <section class="product-section">
      <div class="product-section-container">
        <div class="product-section-container__title">解决方案及产品</div>
        <i class="iconfont icon-yuanquan"></i>
        <span class="product-section-container__english">PRODUCT SOLUTIONS</span>
        <div class="product-section-details">
          <div class="product-section-list" v-for="item in product" :key="item.content">
            <img :src="item.img" alt="" />
            <p class="product-list__title">{{ item.title }}</p>
            <p class="product-list__content">{{ item.content }}</p>
            <div class="product-list__hover"></div>
          </div>
        </div>
        <div class="product-section-Know-details">
          <img src="../../src/assets/img/HOWSEEN.png" alt="" />
          <router-link to="product">了解详情</router-link>
        </div>
      </div>
    </section> -->
    <!-- <section class="case">
      <div class="case__title">合作案例</div>
      <i class="iconfont icon-yuanquan"></i>
      <span class="case__english">COOPERATION CASE</span>
      <div class="case-content">
        <div class="case-content-list" v-for="item in homeCase" :key="item.title">
          <img :src="item.img" alt="" />
          <p class="case-content-list__title">{{ item.title }}</p>
          <p class="case-content-list__english">{{ item.english }}</p>
        </div>
      </div>
    </section> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      navButtom: [
        {
          img: require("../assets/img/homeBanner01.png"),
          bigText: "多种解决方案",
          small: "定制化解决方案",
        },
        {
          img: require("../assets/img/homeBanner02.png"),
          bigText: "产品自主研发",
          small: "拥有自营工厂",
        },
        {
          img: require("../assets/img/homeBanner03.png"),
          bigText: "系统自主研发",
          small: "拥有研发团队，自主研发",
        },
        {
          img: require("../assets/img/homeBanner04.png"),
          bigText: "完善售后服务",
          small: "成熟的售后处理体系",
        },
      ],
      aboutRight: [
        {
          img: require("../assets/img/homeAbout01.png"),
          title: "优质服务",
          english: "Quality service",
        },
        {
          img: require("../assets/img/homeAbout02.png"),
          title: "信息安全",
          english: "Information security",
        },
        {
          img: require("../assets/img/homeAbout03.png"),
          title: "自主研发",
          english: "Independent research",
        },
        {
          img: require("../assets/img/homeAbout04.png"),
          title: "产品保证",
          english: "Product guarantee",
        },
      ],
      product: [
        {
          img: require("../../src/assets/img/homeProduct01.jpg"),
          title: "智慧呼叫对讲系统",
          content:
            "智能护理呼叫系统基于liuxs平台研发，具有良好的开放性和可扩展性，旨在为患者提供舒适的住院环境....",
        },
        {
          img: require("../../src/assets/img/homeProduct02.jpg"),
          title: "电子床头卡、门口机系统",
          content:
            "该系统与医院HIS、CIS、运维系统进行无缝对接，有效的解决了医院病房传统床头卡带来的种种弊端。支....",
        },
        {
          img: require("../../src/assets/img/homeProduct03.jpg"),
          title: "智慧生命体征采集系统",
          content:
            "生命体征采集是一种集合大数据、物联网、移动通信等信息技术+医疗设备专业制造技术的新型护理仪....",
        },
        {
          img: require("../../src/assets/img/homeProduct04.jpg"),
          title: "智慧医气报警系统",
          content:
            "采用无线信号传输，位置布置灵活，不受线路影响，检测气体多样化，解决多种气体检测易混乱的问....",
        },
      ],
      homeCase: [
        {
          img: require("../assets/img/homeCase01.jpg"),
          title: "新都第二人民医院",
          english: "Xindu Second People's Hospital",
        },
        {
          img: require("../assets/img/homeCase02.jpg"),
          title: "四川省人民医院",
          english: "Sichuan Provincial People's Hospital",
        },
        {
          img: require("../assets/img/homeCase03.jpg"),
          title: "新都木兰社区卫生服务中心",
          english: "Xindu Mulan Community Health Service Center",
        },
      ],
    };
  },
  methods: {
    toNext() {
      document.getElementById("next").scrollIntoView();
    },
  },
};
</script>

<style lang="scss" scoped>

// .case {
//   width: 1200px;
//   margin: 0 auto;
//   padding-top: 70px;

//   .case__title {
//     font-size: 28px;
//     font-weight: bold;
//     line-height: 41px;
//     color: #333333;
//   }

//   i {
//     font-size: 12px;
//     color: #009049;
//     font-weight: 700;
//     vertical-align: middle;
//     margin-right: 8px;
//     display: inline-block;
//     margin-top: -10px;
//   }

//   .case__english {
//     font-size: 18px;
//     font-family: Microsoft YaHei UI;
//     font-weight: 400;
//     color: #666666;
//     display: inline-block;
//     margin-top: 15px;
//   }

//   .case-content {
//     width: 100%;
//     height: 300px;
//     margin-top: 50px;
//     margin-bottom: 225px;
//     display: flex;
//     justify-content: space-between;

//     .case-content-list {
//       width: 380px;
//       height: 300px;
//       position: relative;
//       font-family: Microsoft YaHei UI;

//       img {
//         width: 100%;
//         height: 100%;
//         position: absolute;
//       }

//       .case-content-list__title {
//         position: absolute;
//         font-size: 22px;
//         color: #fff;
//         top: 73.5%;
//         margin-left: 30px;
//         font-weight: 700;
//       }

//       .case-content-list__english {
//         position: absolute;
//         font-size: 12px;
//         color: #fff;
//         top: 85%;
//         margin-left: 30px;
//       }
//     }
//   }
// }

.product-section {
  background-color: #F8F8F8;
  width: 100%;

  .product {
    width: 1200px;
    margin: 0 auto !important;

    .about-title {
      font-size: 44px;
      font-weight: bold;
      color: #333333;
      padding-top: 85px;
      box-sizing: border-box;

      span {
        color: #009049;
      }
    }

    .about-english {
      font-size: 20px;
      font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
      color: #666666;
      margin-top: 10px;
    }
  }

  .product-content {
    width: 1200px;
    margin: 0 auto;
    margin-top: 15px;
    padding-bottom: 85px !important;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .product-list:hover {
      background-color: #009049;
      cursor: pointer;

      .product-text {
        div:nth-child(1) {
          color: rgba(255, 255, 255, 0.55);
        }

        div:nth-child(2) {
          color: #fff;
        }

        i {
          background-color: #fff;
        }

        div:nth-child(4) {
          color: rgba(255, 255, 255, 0.65);
        }
      }
    }

    .more {
      width: 275px;
      height: 390px;
      margin-top: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      div {
        width: 184px;
        height: 58px;
        background: #009049;
        border-radius: 34px;
        font-size: 20px;
        font-weight: 400;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
      }

    }

    .product-list {
      width: 275px;
      height: 390px;
      background-color: #fff;
      margin-top: 35px;

      img {
        width: 100%;
        height: 185px;
      }

      .product-text {
        margin: 15px 25px 25px 25px;
        box-sizing: border-box !important;
        height: calc(100% - 185px - 15px - 25px);

        div:nth-child(1) {
          font-size: 30px;
          font-family: D-DIN DIN-Bold, D-DIN DIN;
          font-weight: bold;
          color: rgba(0, 0, 0, 0.08);
        }

        i {
          width: 49px;
          height: 2px;
          background: #009049;
          border-radius: 1px;
          display: block;
          margin: 8px 0;
        }

        div:nth-child(2) {
          font-size: 18px;
          font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
          font-weight: bold;
          color: #333333;
        }

        div:nth-child(4) {
          font-size: 14px;
          font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
          font-weight: 400;
          color: #666666;
          line-height: 24px;
        }
      }
    }
  }
}
.case{
  background-color: #fff;
  .case-content{
    // background-color: red;
    margin-top: 50px;
  }
}
// .product-section-list:hover .product-list__title {
//   color: #009049 !important;
// }

// .product-section-list:hover .product-list__hover {
//   display: block !important;
// }

// .product-section {
//   width: 100%;
//   padding-top: 110px;
//   background: #f8f8f8;
//   padding-bottom: 75px;

//   .product-section-container {
//     width: 1200px;
//     margin: 0 auto;

//     .product-section-container__title {
//       font-size: 28px;
//       font-weight: bold;
//       line-height: 41px;
//       color: #333333;
//     }

//     i {
//       font-size: 12px;
//       color: #009049;
//       font-weight: 700;
//       vertical-align: middle;
//       margin-right: 8px;
//       display: inline-block;
//       margin-top: -10px;
//     }

//     .product-section-container__english {
//       font-size: 18px;
//       font-family: Microsoft YaHei UI;
//       font-weight: 400;
//       color: #666666;
//       display: inline-block;
//       margin-top: 15px;
//     }

//     .product-section-details {
//       width: 100%;
//       height: 400px;
//       margin-top: 50px;
//       display: flex;
//       justify-content: space-between;

//       .product-section-list {
//         width: 275px;
//         height: 100%;
//         background-color: #fff;

//         img {
//           width: 100%;
//           height: 185px;
//         }

//         .product-list__title {
//           font-size: 18px;
//           font-family: Microsoft YaHei UI;
//           font-weight: bold;
//           line-height: 25px;
//           color: #333;
//           margin: 30px 0 0 30px;
//         }

//         .product-list__content {
//           font-size: 12px;
//           font-family: Microsoft YaHei UI;
//           font-weight: 400;
//           line-height: 25px;
//           color: #666666;
//           width: 210px;
//           height: 80px;
//           margin: 25px 0 0 30px;
//           border-top: 1px solid #d1d1d1;
//           padding-top: 25px;
//         }

//         .product-list__hover {
//           width: 100%;
//           height: 5px;
//           background-color: #009049;
//           margin-top: 22.5px;
//           border-radius: 5px;
//           display: none;
//         }
//       }
//     }
//   }

//   .product-section-Know-details {
//     margin-top: 50px;
//     display: flex;
//     justify-content: space-between;

//     img {
//       width: 600px;
//       height: 130px;
//     }

//     a {
//       margin-top: 20px;
//       width: 160px;
//       height: 50px;
//       line-height: 50px;
//       text-align: center;
//       background-color: #009049;
//       border-radius: 25px;
//       font-size: 18px;
//       font-weight: 400;
//       color: #fff;
//     }
//   }
// }

.about-us {
  width: 100%;
  height: 765px;
  position: relative;

  .about__right {
    width: 50%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 0;
    right: 0%;
    background: url('../assets/img/home-mansion.png') no-repeat;
    background-size: 100% 100%;
  }

  .about__left {
    width: 1200px;
    height: 100%;
    margin: 0 auto !important;
    z-index: 999999999 !important;
    position: relative;

    .about__left__content {
      width: 685px !important;
      height: 100%;
      z-index: 9999999999 !important;

      .about-title {
        font-size: 44px;
        font-weight: bold;
        color: #333333;
        padding-top: 85px;
        box-sizing: border-box;

        span {
          color: #009049;
        }
      }

      .about-english {
        font-size: 20px;
        font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
        color: #666666;
        margin-top: 10px;
      }

      p {
        font-size: 16px;
        color: #666666;
        margin-top: 30px;
      }

      .paragraph {}

      .about-more {
        background-color: rebeccapurple;
        margin-top: 30px;
        width: 155px;
        height: 50px;
        background: #009049;
        border-radius: 34px;
        font-size: 20px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .about-more:hover {
        cursor: pointer;
      }

      .about-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 50px;

        .about-list-li {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            width: 47px;
            height: 47px;
          }

          div:nth-child(2) {
            font-size: 24px;
            font-weight: bold;
            margin-top: 10px;
          }

          div:nth-child(3) {
            font-size: 12px;
            color: #6B6B6B;
            margin-top: 10px;
          }
        }
      }
    }
  }
}

.nav-display {
  width: 1200px;
  height: 704px;
  margin: 0 auto;
  padding-top: 202px;

  .nav-intelligent__ward {
    width: 305.22px;
    height: 71.91px;
  }

  .nav-intelligent__ward-english {
    margin-top: 20px;

    span {
      font-size: 16px;
      font-family: Microsoft YaHei UI;
      font-weight: 400;
      line-height: 20px;
      color: #3b3b3b;
      margin-right: 10px;
    }

    .nav__ward-english {
      display: inline;
      margin-left: 15px;
    }
  }

  .nav__wisdom {
    width: 437px;
    height: 57px;
    background: #009049;
    font-size: 34px;
    font-family: FZLanTingHei-R-GBK;
    font-weight: 400;
    padding-bottom: 3px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  .nav__wisdom-english {
    margin-top: 20px;

    div {
      font-size: 18px;
      font-family: Microsoft YaHei UI;
      font-weight: 400;
      color: #3b3b3b;
    }
  }

  .nav__learn-more {
    width: 184px;
    height: 58px;
    background: #009049;
    border-radius: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 34px;

    a {
      font-size: 18px;
      font-family: FZLanTingHei-R-GBK;
      font-weight: 400;
      line-height: 21px;
      color: #ffffff;
    }

    .nav__learn-more__text {
      margin-right: 10px;
    }
  }
}

.nav-buttom-list {
  height: 96px;
  width: 100%;
  border-right: 1px solid #ffffff;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  .nav-buttom__white {
    width: 53px;
    height: 53px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .nav-buttom__text {
    margin-left: 25px;

    .nav-buttom__big-text {
      font-size: 18px;
      font-family: Microsoft YaHei UI;
      font-weight: bold;
      line-height: 25px;
      color: #ffffff;
      display: block;
    }

    .nav-buttom__small-text {
      font-size: 14px;
      font-family: Microsoft YaHei UI;
      font-weight: 400;
      line-height: 20px;
      color: #ffffff;
      display: block;
      margin-top: 6px;
    }
  }
}

.homeWarpper {
  width: 100%;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
    /* Chrome Safari */
  }


  .section-one {
    height: 800px;
    // background-image: url("../assets/img/navImg.png");
    background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    position: relative;

    .nav-buttom {
      width: 100%;
      height: 96px;
      background: rgba(0, 0, 0, 0.39);
      position: absolute;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .nav-buttom-middle {
        width: 1200px;
        height: 100%;
        border-left: 1px solid #ffffff;
        box-sizing: border-box;
        display: flex;
        justify-content: space-evenly;
      }
    }

    i {
      font-size: 60px;
      color: #ffffff;
      position: absolute;
      top: 75%;
      left: 50%;
      cursor: pointer;
      animation: changes 1s 0.2s linear infinite alternate;
    }
  }
}

@keyframes changes {
  0% {
    transform: scale(0.5);
  }

  10% {
    transform: scale(0.6);
  }

  20% {
    transform: scale(0.7);
  }

  30% {
    transform: scale(0.8);
  }

  40% {
    transform: scale(0.9);
  }

  50% {
    transform: scale(1);
  }

  60% {
    transform: scale(1.1);
  }

  70% {
    transform: scale(1.2);
  }

  80% {
    transform: scale(1.3);
  }

  90% {
    transform: scale(1.4);
  }

  100% {
    transform: scale(1.5);
  }
}
</style>
