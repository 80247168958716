<template>
  <div class="wapper">
    <section class="section">
      <div class="contentWarpper scheme-title">
        <img src="../assets/img/aboutH1.png" alt="" />
      </div>
    </section>
    <div class="contentWarpper about">
      <p class="current-position">
        您当前的位置：<router-link to="/home">首页</router-link> > 关于昊森
      </p>
      <div class="section__title">关于昊森</div>
      <i class="iconfont icon-yuanquan"></i>
      <span class="section__english">ABOUT HOWSEEN</span>
      <div class="howseen-details">
        <img src="../assets/img/aboutUs.jpg" alt="" />
        <div class="howseen-introduce">
          <p class="howseen-introduce__title">
            关于昊森 <span> ABOUT HOWSEEN</span>
          </p>
          <p>
            &emsp;&emsp;成都昊森电子科技有限公司（以下简称昊森公司，是成都康宇医用设备工程有限公司旗下的专门从事智能病房系统研发、生产、营销的公司），注册成立于成都金牛高科技产业园振兴西一路19号迎宾国际A-4-2号，在成都金牛区设有营销中心，在成都市新都区宝光大道南段704号设立研发中心和生产基地。专注于智能病房系统的研发，生产和服务。<br />
            &emsp;&emsp;成都昊森电子科技有限公司，位于四川省商业繁华地段成都市金牛区。是从事软件开发、设计、生产的专业公司，服务于医疗行业，与多个省内医院达成合作......<br />
            &emsp;&emsp;目前，随着互联网的飞速发展，政府也在大力推动移动互联网、云计算、大数据、物联网等与现代制造业结合，促进以患者为中心、互联网医疗、远程医疗健康发展。基于此形式下，康宇公司，结合自身的行业优势，以互联网为平台，与中国科学院、清华大学等共同、研发、制造智能病房管理系统，开创国内第一个开放式，WiFi智慧病房系统。
          </p>
        </div>
      </div>
    </div>
    <div class="enterprise-spirit">
      <div class="contentWarpper enterprise-spirit__content">
        <div>
          <p>企业精神&nbsp;<span>ENTERPRISE SPIRIT</span></p>
          <p>
            企业精神以观念为基础、 以价值目标为动力， 对企业经营哲学、
            管理制度、 道德风尚、 团体意识和企业形象起着决定性的作用。
            可以说，企业精神是企业的灵魂。
          </p>
          <p>昊森科技秉承高效、 专业， 创新、 诚信的企业精神。</p>
        </div>
        <img src="../assets/img/enterpriseSpirit.png" alt="" />
      </div>
    </div>
    <div class="contentWarpper about">
      <div class="section__title">企业荣誉</div>
      <i class="iconfont icon-yuanquan"></i>
      <span class="section__english">ENTERPRISE HONOR</span>
      <div class="honor">
        <img src="../assets/img/honor1.png" alt="" />
        <img src="../assets/img/honor2.png" alt="" />
        <img src="../assets/img/honor3.png" alt="" />
        <img src="../assets/img/honor4.png" alt="" />
        <img src="../assets/img/honor5.png" alt="" />
      </div>
      <div class="section__title">加入我们</div>
      <i class="iconfont icon-yuanquan"></i>
      <span class="section__english">CONTACT US</span>
      <div class="map">
        <!-- <mapTest></mapTest> -->
        <!-- <div id="container"></div> -->
        <div id="container" style="width: 100%; height: 460px;"></div>
        <!-- <el-map :center="center" :zoom="zoom">
          <el-marker :position="position"></el-marker>
        </el-map> -->
        <!-- <baidu-map :center="center" :zoom="zoom" @ready="handler" style="width: 100%; height: 460px"></baidu-map> -->
        <!-- <div id="map" ref="mapcontainer"></div> -->
        <div class="map-details">
          <p class="map-detaild__title">销售电话</p>
          <p>028-87750979</p>
          <p class="map-detaild__title">公司网址</p>
          <p>www.howseen.net</p>
          <p class="map-detaild__title">服务热线</p>
          <p>028-87750979</p>
          <p class="map-detaild__title">公司地址</p>
          <p>金牛区迎宾国际-A座</p>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- <script type="text/javascript" src="http://yarn.amap.com/maps?v=2.0&key=7aabecb6fb6f24c69530851398a47692"></script> -->
<script>
import AMapLoader from '@amap/amap-jsapi-loader';//引入
window.AMapSecurityConfig = {
  securityJsCode: "bbf8fa8ba2e11b9daaedc5df9da30e4e",
};
export default {
  data() {
    return {
      map: null,
    };
  },
  methods: {
    // var marker = new AMap.Marker({
    //   // position: AMap.map.getCenter(),
    //   icon: 'https://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',
    //   anchor: 'bottom-center',
    //   // offset: new Pixel(0, 0)
    // });
    // marker.setMap(this.map);
    // // 设置鼠标划过点标记显示的文字提示
    // marker.setTitle('公司地址');
    // // label默认蓝框白底左上角显示，样式className为：amap-marker-label
    // marker.setLabel({
    //   direction: 'right',
    //   // offset: new Pixel(10, 0),  //设置文本标注偏移量
    //   content: "<div class='flex'><div class='info'>迎宾国际-A座</div><br /> <span class='address'>地址：四川省成都市金牛区振兴西一路19号</span></div>", //设置文本标注内容
    // });
  },
  mounted() {
    //   this.initMap()
    AMapLoader.load({
      key: "7aabecb6fb6f24c69530851398a47692", //此处填入我们注册账号后获取的Keyversion: "2.0", 
      version: "2.0", //指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      //指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      plugins: [],
    }).then(AMap => {
      new AMap.Map('container', {
        center: [116.397428, 39.90923],
        zoom: 13
      });
    })
  }
}
</script>

<style lang="scss" scoped>
.about {
  .honor {
    width: 100%;
    height: 310px;
    margin-top: 45px;
    display: flex;
    justify-content: space-between;

    img {
      width: 255px;
      height: 100%;
    }
  }

  .map {
    width: 100%;
    height: 460px;
    margin-top: 45px;
    background: url("../assets/img/map.jpg") no-repeat;
    // background-color: red;
    // position: relative;

    #container {
      background-color: red;
      // position: absolute;
      // top: 0;
      width: 1200px;
      height: 460px;
      padding: 0px;
      margin: 0px;
      // z-index: 9999 !important;
    }

    #map {
      // position: absolute;
      // top: 0;
      width: 1200px;
      height: 460px;
    }

    .map-details {
      position: absolute;
      width: 300px;
      height: 300px;
      left: 40px;
      top: 40px;
      background-color: #fff;
      padding: 25px 45px;
      box-sizing: border-box;
      z-index: 99999 !important;


      p {
        line-height: 25px;
        font-size: 14px;
        color: #858585;
      }

      .map-detaild__title {
        font-size: 16px;
        font-weight: 700;
        color: #000;
        margin-top: 10px;
      }
    }
  }
}

.enterprise-spirit {
  width: 100%;
  height: 500px;
  background-color: #fafafa;

  .enterprise-spirit__content {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 80px;
    padding-left: 120px;
    box-sizing: border-box;

    div {
      height: 230px;
      width: 330px;

      p:nth-child(1) {
        font-size: 22px;
        font-family: Microsoft YaHei UI;
        font-weight: bold;
        line-height: 30px;
        color: #333333;
        margin-bottom: 20px;

        span {
          color: #d2d2d2;
          font-weight: 400;
        }
      }

      p:nth-child(2),
      p:nth-child(3) {
        font-size: 14px;
        font-family: Microsoft YaHei UI;
        font-weight: 400;
        line-height: 30px;
        color: #666666;
        text-indent: 2em;
        opacity: 1;
      }
    }

    img {
      width: 550px;
      height: 390px;
    }
  }
}

.about {
  margin-top: 30px;
  margin-bottom: 100px;

  .current-position {
    font-size: 16px;
    font-family: Microsoft YaHei UI;
    color: #666666;

    a {
      color: #009049;
    }
  }

  .section__title {
    font-size: 28px;
    font-weight: bold;
    line-height: 41px;
    color: #333333;
    margin-top: 65px;
  }

  i {
    font-size: 12px;
    color: #009049;
    font-weight: 700;
    vertical-align: middle;
    margin-right: 8px;
    display: inline-block;
    margin-top: -10px;
  }

  .section__english {
    font-size: 18px;
    font-family: Microsoft YaHei UI;
    font-weight: 400;
    color: #666666;
    display: inline-block;
    margin-top: 15px;
  }

  .howseen-details {
    height: 300px;
    width: 100%;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;

    img {
      height: 100%;
      width: 375px;
    }

    .howseen-introduce {
      height: 100%;
      width: 780px;

      .howseen-introduce__title {
        font-size: 22px;
        font-family: Microsoft YaHei UI;
        font-weight: bold;
        line-height: 30px;
        color: #333333;

        span {
          color: #d2d2d2;
          font-weight: 400;
        }
      }

      p:nth-child(2) {
        color: #666666;
        font-size: 13px;
        font-family: Microsoft YaHei UI;
        font-weight: 400;
        line-height: 28px;
        margin-top: 10px;
        text-indent: 2em;
      }
    }
  }
}

.section {
  background: url("../assets/img/caseBanner.jpg") no-repeat center;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  height: 455px;
  padding-top: 110px;
  box-sizing: border-box;

  .scheme-title {
    img {
      width: 675px;
      height: 230px;
    }
  }
}

.amap-marker-label {
  border: 0;
  background-color: transparent;
}
</style>
